@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import "~bootstrap/scss/bootstrap";

$blue-color-first: #3056d3;
$blue-color-second: #cde8fe;
$blue-color-third: #edf8f8;

$yellow-color-first: #ffed38;
$yellow-color-second: #fff9c8;

$gray-color-first: #e8ebf3;
$gray-color-second: #f2f5fc;

$green-color-first: #56e68f;
$green-color-second: #aef2c9;

$pink-color-first: #ffa3da;
$pink-color-second: #ffdbf1;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: black;
  background-color: $blue-color-third !important;
  opacity: 0;
  animation: flyUp 1s forwards;
  overscroll-behavior: none;

  .navbar-collapse {
    .navbar-nav {
      text-align: center;
      display: block;

      .nav-item {
        display: inline-block;
        width: 115px;
        padding: 15px 0;

        @media (min-width: 992px) {
          padding: 15px 8px;
          width: auto;
        }
      }
    }
  }
}

ul {
  margin: 0;
}

:focus {
  outline: 0;
}

@keyframes flyUp {
  100% {
    opacity: 1;
  }
}

ol, ul {
  list-style: none;
  padding: 0;
}

input::placeholder {
  color: black;
}

b, strong {
  font-weight: bold;
}


.btn {
  display: inline-block;
  padding: 5px 1rem;
  color: black;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  outline: 0;
  background-color: $blue-color-third;
  border: 1px solid $blue-color-third;
  white-space: nowrap;
  transition: background-color 0.5s ease;
  cursor: pointer;
  font-weight: 500;

  &.logout, &.download {
    background-color: $blue-color-third;
    color: black;
    border-color: $blue-color-third;

    &:hover {
      background-color: transparent;
      border-color: black;
      color: black;
    }
  }

  &:hover, &:focus {
    background-color: $yellow-color-second;
    border-color: $yellow-color-second;
    color: black;

  }
}

.header-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  background-color: $blue-color-first;
}

.navbar-toggler {
  font-size: 3rem;
  padding: 0;
  border: 0;
  color: $yellow-color-first;
  box-shadow: none !important;
}

.brand {
  display: inline-block;
  padding: 0;
  font-size: 33px;
  color: white !important;
  text-decoration: none;
  line-height: 90px;

  img {
    width: 145px;
    height: auto;
  }
}

.navbar {
  background-color: $blue-color-first;
  padding: 0;
  color: white;
}

.menu-item {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 14px;
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 8px;
  margin: 0 5px;
  transition: background-color 0.5s ease;
  overflow: inherit;

  &:hover, &:focus {
    background-color: $blue-color-second;
    color: black;

    span {
      &.badge {
        background-color: $green-color-second;
        color: black;
      }

      &.notification-point {
        background-color: $green-color-second;
      }
    }

    .reating {
      color: black !important;
    }

    svg {
      path {
        color: black;
        fill: black;
      }
    }
  }

  .cover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin: 0 0 6px 0;
    padding: 0;

    svg {
      width: 30px;
      height: 30px;
    }

    .reating {
      font-size: 18px;
      line-height: 20px;
      color: $yellow-color-first;
    }
  }

  &.active {
    color: black;
    background-color: $yellow-color-first;

    .reating {
      color: $blue-color-first;
    }

    .cover svg path {
      fill: black;
    }
  }
}


.title {
  margin: 0 0 1rem 0;
  padding: 0;
  font-size: 24px;
}

.search {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;

  input {
    display: block;
    position: relative;
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    background-color: $blue-color-second;
    border: 0;
    border-radius: 8px;
    color: black;
    outline: 0;
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;
    border-right: 1px solid #19607E;
  }
}


.card {
  position: relative;
  margin: 0;
  padding: 1.5rem;
  background-color: $blue-color-second;
  border-radius: 8px;
  border: 0;

  &.block {
    padding: 1.5rem;
    font-size: 1rem;

    .title {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
    }

    .info {
      .item {
        position: relative;
        padding: 5px 8px;
        color: black;
        background-color: $blue-color-third;
        border-radius: 8px;
        font-size: 12px;
        text-align: center;

        &.time {
          display: block;
        }

        svg {
          margin-right: 5px;
          font-size: 20px;
          vertical-align: middle;
        }

      }
    }
  }
}

.card-footer-pc {
  display: block;
  position: absolute;
  right: 1.5rem;

  ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;

    li {
      display: block;
      margin-bottom: 10px;
      padding: 0;
      list-style: none;

      .button {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        background-color: $blue-color-third;
        border-radius: 50%;
        transition: all .2s ease;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);

        svg {
          color: black;
        }

        &:hover, &:focus {
          display: block;
          position: relative;
          width: 30px;
          height: 30px;
          border: 0;
          background-color: $yellow-color-first;
          border-radius: 50%;
          transition: all .2s ease;
          cursor: pointer;
        }

        &:hover {
          border-color: $blue-color-first;
        }
      }
    }
  }
}

.cat-title {
  margin-bottom: 1rem;
  font-size: 18px;
}

.card-body {
  padding: 0;
}

.card-footer-mb .messenger {
  display: none;
  position: relative;
  overflow: hidden;
  height: 170px;
  margin: 10px 0 0 0;
  padding: 0;
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 8px;


}

.messages {
  ul {
    display: block;
    padding: 1rem;
    list-style: none;
    height: 225px;

    li {
      display: block;
      margin: 0;
      list-style: none;
      padding: 5px 0;
    }
  }

  .mess {
    display: flex;
    flex-direction: column;
    text-align: left;

    &.from {
      align-items: flex-start;
    }

    &.to, &.admin {
      align-items: flex-end;
    }

    .text {
      margin: 0 0 8px 0;
      padding: 10px;
      font-size: 14px;
      line-height: 14px;
      color: black;
    }

    .file {
      background-color: $blue-color-second;
      display: inline-block;
      margin-left: 8px;
      padding: 8px;
      margin-bottom: 5px;
      border-radius: 5px;
      text-decoration: none;
      text-align: center;
      width: 80px;
      color: black;
      font-size: 1.5rem;

      .name {
        display: block;
        color: black;
        text-decoration: none;
        border: 0;
        font-size: 12px;
        overflow: hidden;
      }
    }

    &.from .text {
      background-color: $yellow-color-second;
      border-radius: 8px;
      color: black;
    }

    &.admin .text {
      background-color: $pink-color-first;
      border-radius: 8px;
      width: 100%;
    }

    &.to .text {
      background-color: $blue-color-second;
      color: black;
      border-radius: 8px;
    }

    .info {
      margin: 0;
      font-size: 10px;
      line-height: 12px;
      color: black;

      .status {
        width: 15px;
        height: 12px;

        svg {
          width: 15px;
          height: 15px;

          &:nth-child(2) {
            left: 4px;
          }
        }
      }
    }
  }
}

form {
  position: relative;
}

.card-messenger {
  position: relative;
  overflow: hidden;
  padding: 0;
  background-color: $blue-color-third;
  border-radius: 8px;
  border: 0;

  .point {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0 !important;
    margin: 0 !important;
    display: inline-block;
    float: right;
    border: 2px solid $green-color-first;
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 0.3em;
  }

  ::-webkit-scrollbar-track {
    background-color: $blue-color-third;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 8px;
  }

  .msg-lg-body {
    margin: 0;
    padding: 0;
  }

  .msg-lg-info {
    height: 100%;
    margin: 0;
    padding: 20px;
    background-color: $gray-color-first;

    ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: block;
        margin: 0;
        list-style: none;
        position: relative;
        padding: 5px;
        font-size: 12px;
        line-height: 20px;

        svg {
          font-size: 20px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }
  }

  .msg-lg-input {
    overflow: hidden;
    border-radius: 8px;
  }
}

.upload-file-hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.upload-file-label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  font-weight: normal !important;
  padding: 10px 12px;
  background-color: white;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;

  svg {
    margin-right: 5px;
  }
}

.card-messenger .msg-lg-input {
  .message {
    display: block;
    position: relative;
    width: 100%;
    height: 34px;
    padding: 6px 50px 6px 15px;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: black;
    background-color: white;
    border-radius: 8px;
    outline: 0;
    border: 0;
  }

  .go {
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    border-radius: 0 0 8px 0;
    background-color: $blue-color-second;
    color: $blue-color-first;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;

    svg {
      vertical-align: middle;
    }
  }
}

.error {
  color: $blue-color-first;
  font-size: 12px;
  margin-top: 5px;
}

.errorMessage {
  color: black;
  background-color: $pink-color-second;
  border: 1px solid $pink-color-second;
  padding: 1rem;
  border-radius: 0.25rem;
}

.successfulMessage {
  color: black;
  background-color: $green-color-second;
  border: 1px solid $green-color-second;
  padding: 1rem;
  border-radius: 0.25rem;
}

.end-time {
  background: $pink-color-second !important;
}

.reports-modal {
  overflow-y: scroll;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $blue-color-third;
  box-shadow: 0 0 6px #00000033;
  width: 500px;
  max-width: 95%;
  padding: 2rem;

  select {
    width: 100%;
    height: 34px;
    padding: 6px 50px 6px 15px;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: black;
    background-color: $blue-color-second;
    border: 0;
    border-radius: 8px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .buttom {
    text-align: right;
    margin-top: 1rem;
  }
}

.close {
  position: absolute;
  color: #000;
  opacity: 1;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  svg {
    font-size: 2rem;
    color: red;
  }
}

.reports-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000d6;
  z-index: 9999;
}

.mini-btn {
  color: black;
}

.center {
  text-align: center;
  margin: 0 auto;
}

.rating {
  background-color: $blue-color-third;
  padding: 1rem;
  border-radius: 8px;

  li {
    cursor: pointer;
    border-radius: 50%;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-right: 10px;
    padding: 0 !important;
    line-height: 30px;
    background-color: $blue-color-second;
    color: black;
    transition: background-color 0.5s ease;

    &.active {
      background-color: $yellow-color-first !important;
    }

    &:hover {
      background-color: $yellow-color-first !important;
      color: black;
    }

    svg {
      vertical-align: middle;
    }
  }
}

.slider {
  background-color: $blue-color-second;
  width: 100%;
  height: 250px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 2rem;

  h1, span {
    color: black;
    font-size: 2rem;
    line-height: 250px;
  }

  &.blue {
    background-color: $blue-color-second;
  }

  &.yellow {
    background-color: $yellow-color-second;
  }
}


.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    + .radio-label {
      cursor: pointer;

      &:hover {
        &:before {
          background-color: $yellow-color-second;
        }
      }

      &:before {
        content: '';
        background-color: $blue-color-second;
        border-radius: 100%;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: 0;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
      }
    }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $yellow-color-first;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $yellow-color-first;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #1c3b6c;
          border-color: darken(#1c3b6c, 25%);
          background-color: darken(#1c3b6c, 25%);
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.applied-alert {
  background-color: $yellow-color-second !important;
  color: black !important;
  border-radius: 8px !important;

  svg {
    color: black !important;
  }
}

footer {
  background-color: white;
  text-align: center;
  padding: 2rem 0;
  color: black;
}

.tab-user {
  border: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;

  .react-tabs__tab--selected {
    background-color: transparent;

    .is_online {
      svg {
        color: $blue-color-first;
      }
    }

    .block {
      border-color: $blue-color-first;

      .rating-icon {
        background-color: $blue-color-second;
      }

      .bg-green-second {
        background-color: white !important;
      }
    }
  }

  .block {
    li {
      background: transparent;
      margin-bottom: 10px;
      margin-right: 0;
      padding: 0;
      padding-bottom: 10px;
      border-bottom: 1px dashed $blue-color-second;
      border-radius: 0;

      a {
        color: black;
        text-decoration: none;
        display: block;

        &:hover {
          color: $blue-color-first;

          .rating-icon {
            background-color: $blue-color-first;
            color: $blue-color-second;
          }
        }
      }

      &:hover {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      &:last-child {
        border: 0;
        margin: 0;
        padding: 0;
      }
    }

    .rating-icon {
      background-color: white;
      padding: 5px;
      clip-path: polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%);
      display: inline-block;
      font-size: 30px;
      width: auto;
      margin-right: 10px;
      margin-bottom: 0;
      color: $blue-color-first;
      transition: background-color 0.8s ease;
    }

    .rating-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .is_online {
    z-index: 9;
    height: 20px;
    display: inline-block;
    margin: 0 auto;
    position: absolute;
    right: 25px;
    bottom: 15px;

    svg {
      color: transparent;
      font-size: 20px;
    }
  }

  .user {
    background: $blue-color-third;
    border-radius: 8px;
    transition: box-shadow 0.1s ease-in-out;
    cursor: pointer;
    padding: 1rem;
    border: 1px solid $blue-color-third;

    &.online {
      background-color: $green-color-first;
      border: 1px solid $green-color-first;

      .block {
        li {
          border-color: $green-color-second !important;
        }
      }

      .rating-icon {
        background-color: $green-color-second !important;
        color: black !important;
      }
    }

    .comment {
      text-align: center;
      background-color: $yellow-color-first;
      color: $blue-color-first;
      transition: background-color 0.8s ease;
      right: -15px;
      top: 15px;
      width: 30px;

      &:hover {
        background-color: $blue-color-first;

        svg {
          color: $yellow-color-first;
        }
      }
    }

    .executor-rating {
      background-color: $yellow-color-first;
      color: $blue-color-first;
    }

    .name {
      color: black;
    }

    &:hover {
      -webkit-box-shadow: 0 0 5px 0 #223c5021 inset;
      -moz-box-shadow: 0 0 5px 0 #223c5021 inset;
      box-shadow: 0 0 5px 0 #223c5021 inset;
    }
  }
}

.content {
  min-height: calc(100vh - 205px);
  margin-top: 120px;
}

.nav {
  background-color: $green-color-second;
  padding: 1rem;
  border-radius: 8px;

  ul {
    width: 100%;

    li {
      display: block;
      width: 100%;
      border-bottom: 1px solid $green-color-first;

      &:last-child {
        border: 0;
      }

      &:hover {
        background-color: $green-color-first;
        border-radius: 3px;
      }

      a {
        color: black;
        width: 100%;
        display: block;
        padding: 10px 5px;
        text-decoration: none;
      }
    }
  }
}

.custom-top-bar {
  backdrop-filter: blur(20px);
  border-style: solid;
  border-color: #E7EBF0;
  border-width: 0;
  border-bottom-width: thin;
  background: #ffffffb3;
  color: #2D3843;
  box-shadow: none;
  position: fixed;
  width: 100%;
  top: 0;

  .MuiButtonBase-root {
    border: 1px solid #E0E3E7;
    border-radius: 10px;
    color: #007FFF;
    margin-left: 15px;
    padding: 6px;
  }
}

.block {
  margin: 0 auto;
}

.card-form {
  padding: 20px;
  border-radius: 8px;
  background: #cde8ff;
  color: black;

  label {
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
  }

  .alert-danger {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  .image {
    text-align: center;

    img {
      border-radius: 50%;
      margin-bottom: 1rem;
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }

  input, select {
    width: 100%;
    height: 34px;
    padding: 1rem;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: black;
    background-color: $gray-color-second;
    border: 0;
    border-radius: 8px;
  }

  textarea {
    width: 100%;
    padding: 1rem;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: black;
    background-color: $gray-color-second;
    border: 0;
    border-radius: 8px;
  }
}

.MuiPagination-root {
  background: white;
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  margin: 0;
}

.MuiPagination-root {
  ul {
    li {
      button {
        background: $blue-color-second;
        margin: 0 5px;
        border-radius: 8px;
        color: black;
      }

      .Mui-selected {
        background: $blue-color-first !important;
      }
    }
  }
}

.profile-image {
  img {
    border-radius: 50%;
    max-width: 150px;
  }
}

.profile-image-none {
  background-color: $blue-color-third;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  line-height: 150px;

  svg {
    fill: $blue-color-second;
    font-size: 6rem;
    color: black;
  }
}

.text-justify {
  text-align: justify;
}


.avatar {
  width: 35px;
  border-radius: 50%;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background-color: $blue-color-third;
}

::-webkit-scrollbar-thumb {
  background-color: #a6c7e1;
}

.menu-item-additional {
  .badge {
    position: absolute;
    background: $yellow-color-first;
    color: $blue-color-first;
    font-size: 12px;
    padding: 2px 4px;
    font-weight: 400;
    top: -5px;
    right: -5px;
  }

  .notification-point {
    position: absolute;
    top: 18px;
    right: -5px;
    color: $blue-color-first;
    font-size: 15px;
    padding: 2px 4px;
    font-weight: 400;
    background-color: $yellow-color-first;
    border-radius: 0.375rem;
  }
}

.actions-area {
  .btn {
    width: 100%;
    margin-bottom: 10px;

    @media (min-width: 992px) {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.project .card.block {
  border: 1px solid $gray-color-first;

  &.in-progress {
    background-color: $green-color-second;
    border-color: $green-color-first;
  }

  &.disputed {
    background-color: $pink-color-second;
    border-color: $pink-color-first;
  }

  &.closed {
    background-color: $gray-color-first;
  }
}

.user-comment-profile {
  .block {
    border-radius: 8px;

    .image {
      font-size: 50px;
      width: 50px;
      border-radius: 50%;
      color: black;
    }
  }

  .comments {
    li {
      color: black;
      padding: 1rem;
      border-radius: 8px;
    }
  }
}


.bg-green-first {
  background-color: $green-color-first;
  border-color: $green-color-first;

  &:hover {
    background-color: $blue-color-third;
    border-color: $green-color-first;
  }
}

.bg-green-second {
  background-color: $green-color-second;
}

.bg-blue-second {
  background-color: $blue-color-second;
}

.bg-pink-second {
  background-color: $pink-color-second;
}

.animation {
  animation: glowing 1300ms infinite;
}

@keyframes glowing {
  0% { background-color: $green-color-second; box-shadow: 0 0 5px $green-color-first; }
  50% { background-color: $green-color-first; box-shadow: 0 0 20px $green-color-first; }
  100% { background-color: $green-color-first; box-shadow: 0 0 5px $green-color-first; }
}
